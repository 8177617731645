<template>
  <div>
    <Nav :link="{ name: 'home' }" :user="user" />
    <div class="has-text-centered pt-8 px-2">
      <p class="mb-4">
        <img src="@/assets/404.jpg" alt="404" width="200" height="200">
      </p>
      <h1 class="heading-2">
        Page not found.
      </h1>
      <p class="mb-4">
        The page you’re looking for has either moved or no longer exists.
      </p>
      <p>
        <RouterLink :to="{name: 'home'}" class="goo-button is-secondary">
          Go to account settings
        </RouterLink>
      </p>
    </div>
  </div>
</template>

<script>
  import Nav from "@/components/Settings/components/Nav";

  export default {
    name: "PageNotFound",
    components: { Nav },
    props: {
      user: {
        type: Object,
        default: null
      }
    }
  };
</script>

<style lang="scss">

</style>
